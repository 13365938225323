<template>
  <TepmplateBlock
    content-class="mytechnique-wrap pt-4"
    mod-class
    title-page="Общая информация"
  >
    <div v-if="isIntroPassed === 'false'" class="dark-intro-background">
      <div class="intro-tip-wrapper"></div>
    </div>

    <vuescroll>
      <div class="scroll-container">
        <div class="general-info">
          <b-alert
            v-if="!isInnDataExist"
            variant="danger"
            show
            :class="isIntroPassed === 'false' ? 'over-top' : ''"
          >
            Не удалось найти данные по указанному Вами ИНН. Заполните все поля
            вручную
          </b-alert>
          <div class="tip-anchor"></div>

          <div class="intro-tip p-2 w-75 text-center">
            Данные получены по ИНН вашей компании, проверьте и нажмите кнопку
            "Сохранить"
          </div>

          <h2 class="general-info__main-title">
            Реквизиты компании
          </h2>

          <!-- <div v-if="isIntroPassed === 'false'" class="dark-intro-background">
            <div class="intro-tip-wrapper">
        
              <div class="intro-tip p-2 w-25 text-center">
                Данные получены по ИНН вашей компании, проверьте и нажмите кнопку
                "Сохранить"
              </div>
            </div>
          </div> -->

          <form @submit.prevent="onSubmit">
            <Input
              v-model="generalInfo.brand"
              label="Бренд"
              :required="true"
              :is-need-validation="true"
            />

            <!--:class="{ 'over-top': isIntroPassed === 'false' }"
            :new-class="isIntroPassed === 'false' ? 'over-top' : ''" -->

            <Input
              @input="onInputChangeINN"
              v-model="generalInfo.inn"
              label="ИНН"
              :required="true"
              :is-need-validation="true"
            />

            <!--
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :new-class="isIntroPassed === 'false' ? 'over-top' : ''"

            -->

            <Input
              @input="onInputChange"
              v-model="generalInfo.kpp"
              label="КПП"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :new-class="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              @input="onInputChange"
              v-model="generalInfo.ogrn"
              label="ОРГН"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :new-class="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              @input="onInputChange"
              v-model="generalInfo.legal_entity"
              label="Наименование юр. лица"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :new-class="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              @input="onInputChange"
              v-model="generalInfo.legal_entity_address"
              label="Юридический адрес"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :new-class="isIntroPassed === 'false' ? 'over-top' : ''"
            />

            <Input
              @input="onInputChange"
              v-model="generalInfo.fact_address"
              label="Фактический адрес"
              :required="true"
              :is-need-validation="true"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              :new-class="isIntroPassed === 'false' ? 'over-top' : ''"
            />
            <span
              class="error"
              v-if="error.length > 0"
              :class="isIntroPassed === 'false' ? 'over-top' : ''"
              >{{ error }}</span
            >
            <div class="general-info__btn-group justify-content-center">
              <span
                class="btn btn-primary general-info__btn-prev"
                :class="{ 'over-top': isIntroPassed === 'false' }"
                v-if="isIntroPassed === 'true'"
                @click="onGoBack"
              >
                Назад
              </span>
              <!--<router-link
                type="button"
                to="/admin-profile"
                class="btn btn-primary general-info__btn-prev"
                :class="{ 'over-top': isIntroPassed === 'false' }"
                v-if="isIntroPassed === 'true'"
              >
                Назад
              </router-link>-->

              <button
                type="submit"
                class="btn btn-second"
                :class="{ 'over-top': isIntroPassed === 'false' }"
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </vuescroll>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import Input from "../../components/Form/FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
import vuescroll from "vuescroll";
import { CompanyApi as ICompanyApi } from "../../function/request/apiV2";
import { doPoseTip } from "@/utils";

export default {
  name: "GeneralInfo",
  components: { Input, TepmplateBlock, vuescroll },
  data: () => ({
    companyApi: new ICompanyApi(),
    companyId: "",
    error: "",
    generalInfo: {
      email: "",
      brand: "",
      inn: "",
      kpp: "",
      ogrn: "",
      legal_entity: "",
      legal_entity_address: "",
      fact_address: ""
    },
    companyInfo: {},
    isIntroPassed: "true",
    isInnDataExist: true
  }),

  created() {
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
  },

  async mounted() {
    if (this.isIntroPassed === "false") {
      doPoseTip(".tip-anchor", "top");
    }

    const companyInfo = await this.companyApi.api.getCompany(
      this.$route.params.id
    );
    console.log("companyInfo=", companyInfo);

    if (!companyInfo.brand) {
      this.companyApi.api.getCompany(this.$route.params.id).then(data => {
        this.generalInfo.email = data.email;
        this.generalInfo.inn = data.inn;
        this.companyApi.api
          .getData(this.generalInfo.inn)
          .then(response => {
            this.companyId = response.id;
            this.generalInfo.brand = response.brand;
            this.generalInfo.kpp = response.kpp + "";
            this.generalInfo.ogrn = response.ogrn;
            this.generalInfo.legal_entity = response.legalEntity;
            this.generalInfo.legal_entity_address = response.legalEntityAddress;
            this.generalInfo.fact_address = response.legalEntityAddress;
            this.isInnDataExist = true;
            // this.generalInfo = data;
            // пока не решена ситуация с data.crm_manager
          })
          .catch(error => {
            console.log(error);
            this.isInnDataExist = false;
          });
      });
    } else {
      this.companyId = companyInfo.id;
      this.generalInfo.inn = companyInfo.inn;
      this.generalInfo.brand = companyInfo.brand;
      this.generalInfo.kpp = companyInfo.kpp + "";
      this.generalInfo.ogrn = companyInfo.ogrn;
      this.generalInfo.legal_entity = companyInfo.legalEntity;
      this.generalInfo.legal_entity_address = companyInfo.legalEntityAddress;
      this.generalInfo.fact_address = companyInfo.legalEntityAddress;
      this.isInnDataExist = true;
    }
  },

  methods: {
    onGoBack() {
      this.$router.go(-1);
    },
    onInputChange() {
      this.$set(this, "error", "");
    },
    onInputChangeINN() {
      this.generalInfo.brand = "";
      this.generalInfo.kpp = "";
      this.generalInfo.ogrn = "";
      this.generalInfo.legal_entity = "";
      this.generalInfo.legal_entity_address = "";
      this.generalInfo.fact_address = "";
      this.isInnDataExist = false;

      if (this.generalInfo.inn.length > 8) {
        this.companyApi.api
          .getCompany(this.$store.getters.currentUser.company)
          .then(data => {
            this.generalInfo.email = data.email;
            this.companyApi.api
              .getData(this.generalInfo.inn)
              .then(response => {
                this.companyId = response.id;
                this.generalInfo.brand = response.brand;
                this.generalInfo.kpp = response.kpp + "";
                this.generalInfo.ogrn = response.ogrn;
                this.generalInfo.legal_entity = response.legalEntity;
                this.generalInfo.legal_entity_address =
                  response.legalEntityAddress;
                this.generalInfo.fact_address = response.legalEntityAddress;
                this.isInnDataExist = true;
                // this.generalInfo = data;
                // пока не решена ситуация с data.crm_manager
              })
              .catch(error => {
                console.log(error);
                this.isInnDataExist = false;
              });
          });
      }
    },
    onSubmit() {
      let error = "";
      for (let key in this.generalInfo) {
        if (
          this.generalInfo[key] === "" ||
          this.generalInfo[key] === null ||
          this.generalInfo[key] === "null"
        ) {
          error = "Заполните все поля";
          this.error = "Заполните все поля";
        }
      }
      if (error.length === 0) {
        RequestManager()
          .updateCompanyInfo(
            this.$store.getters.currentUser.company,
            this.generalInfo
          )
          .then(async () => {
            const res = await RequestManager().companyApi.getCompany(
              this.$store.getters.currentUser.company
            );
            localStorage.setItem("mainCompanyInfoV2", JSON.stringify(res));
            localStorage.setItem("isGeneralInfoExist", "true");
            this.$router.push({ name: "AdminProfile" });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: red;
}
.error.over-top {
  color: #ff3860 !important;
  font-weight: bold !important;
}
.scroll-container {
  width: 500px !important;
  margin: 0 auto;
  overflow: hidden;
}
.general-info {
  margin: 0 auto;
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 698px;
  min-height: 672px;
  position: relative;
  z-index: 1001;

  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
}

.general-info__btn-group {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 14px;
}

.general-info__btn {
  max-width: 100%;
}

.general-info__btn-prev {
  border: 1px solid #006b90;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  margin-right: 7px;
}

.general-info__main-title {
  margin-bottom: 28px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--card-text-color);
}
</style>
